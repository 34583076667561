import { template as template_11094e7c8c06452d816d2d2d666f47d9 } from "@ember/template-compiler";
const FKLabel = template_11094e7c8c06452d816d2d2d666f47d9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
