import { template as template_86110f91d49c415982e9d75f1c12d38d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_86110f91d49c415982e9d75f1c12d38d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
