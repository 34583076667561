import { template as template_324d73f95cb7406eaabed6720fc8a334 } from "@ember/template-compiler";
const SidebarSectionMessage = template_324d73f95cb7406eaabed6720fc8a334(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
