import { template as template_4803024ff3404d68883c89c0f0190ad6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4803024ff3404d68883c89c0f0190ad6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
